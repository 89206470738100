<template>
  <v-footer app id="footer" class="px-0" :elevation="0">
    <v-container>
      <v-row justify="center">
        <v-col cols="12" sm="7" md="6" lg="4" class="px-0">
          <div class="px-4">
            <v-btn
              @click="
                $router.push({ name: 'MenuWeborders', query: $route.query })
              "
              color="secondary"
              class="px-5 info--text"
              block
              depressed
              x-large
              rounded
            >
              AGREGAR MAS COMIDA
            </v-btn>
          </div>
          <v-divider class="my-4" />
          <div class="px-4">
            <v-btn
              @click="
                $router.push({
                  name: 'MenuWebordersPayment',
                  query: $route.query,
                })
              "
              color="info"
              :disabled="!hasProductsInCart"
              class="px-5"
              block
              depressed
              x-large
              rounded
            >
              CONFIRMAR PEDIDO
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "FooterMenuProductSelectionExtras",
  data() {
    return {};
  },
  computed: {
    hasProductsInCart() {
      return (
        this.$store.getters["deliveryCart/sortedExtendedProducts"].length > 0
      );
    },
  },
};
</script>

<style lang="sass" scoped>
#footer
  background-position: center bottom
  a
    text-decoration: none

#cart-button-text
  letter-spacing: 0.06rem !important
</style>
